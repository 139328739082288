import * as Sentry from '@sentry/react';

export function sgAddUserToMailingList(email, name, uid) {
	// SG API custom fields requires fieldID
	// e6_T=display_name e7_T=premium
	const data = JSON.stringify({
		"contacts": [
			{
				"email": email,
				"first_name": name,
				"custom_fields": {
					"e6_T": name,
					"e7_T": "false",
					"e8_T": uid
				}
			}]
	});

	return fetch('https://api.sendgrid.com/v3/marketing/contacts', {
		method: 'PUT',
		headers: {
			Authorization: `Bearer ${process.env.REACT_APP_SENDGRID_API_KEY}`,
			'content-type': 'application/json'
		},
		body: data
	}).then((res) => {
		res.json();
	}).catch((error) => {
		Sentry.captureException(error);
	});
}

export function sgUpdateUserDetails(detailsObj) {
	// SG API custom fields requires fieldID
	// e6_T=display_name e7_T=premium
	const data = JSON.stringify({
		"contacts": [detailsObj]
	});

	return fetch('https://api.sendgrid.com/v3/marketing/contacts', {
		method: 'PUT',
		headers: {
			Authorization: `Bearer ${process.env.REACT_APP_SENDGRID_API_KEY}`,
			'content-type': 'application/json'
		},
		body: data
	}).then((res) => {
		res.json();
	}).catch((error) => {
		Sentry.captureException(error);
	});
}

export async function sgSearchUserByUID(uid) {
	const query = JSON.stringify({
		"query": `uid LIKE '${uid}'`
	});

	const res = await fetch('https://api.sendgrid.com/v3/marketing/contacts/search', {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${process.env.REACT_APP_SENDGRID_API_KEY}`,
			'content-type': 'application/json'
		},
		body: query
	});
	const data = await res.json();
	return data;
}
