import React, { createContext, useContext, useState, useEffect } from 'react';
import { firestore, fieldVal } from '../firebase';
import { useAuth } from './AuthContext';
import { message } from 'antd';
import { navigate } from '@reach/router';

export const FirestoreContext = createContext({} as any);

// hook to access AuthContext
export function useFirestore() {
	return useContext(FirestoreContext);
}

type Props = {
	children: React.ReactNode;
};

export function FirestoreProvider({ children }: Props) {

	const getCampaignRef = (id: string) => {
		return id.length ? firestore.collection('campaigns').doc(id) : null;
	};
	const changeCampaign = (campaignId: string, userId: string) => {
		const ref = getCampaignRef(campaignId);
		ref && ref.get().then((doc: any) => {
			if (doc.exists) {
				const campaign = {
					...doc.data(),
					id: doc.id
				};
				// Check if user is a member of the campaign
				if (campaign.members.includes(userId) || campaign.id === 'getting-started') {
					// Reset state
					setCampaignData(null);
					setCampaigns([]);
					setTimelineRealtimeEntries([]);
					setTimelineStaticEntries([]);
					setFolders(null);
					setPages(null);
					setCampaignMembers([]);
					// Set new campaign ref
					setCurrentCampaignRef(ref);
					firestore.collection('users').doc(userId).update({ prevCampaign: campaignId });
				} else if (campaign.id.length === 28) {
					// If trying to access someone's personal notes, don't show request modal
					message.error(`403 - You don't have permission to that campaign`);
					navigate('/');
				} else {
					// User is not member of that campaign, show request modal
					message.error(`403 - You are not a member of that campaign`);
					// Request access to campaign
					firestore.collection('users').doc(doc.data().createdBy).get().then((doc: any) => {
						const data = doc.data();
						setRequestCampaign({
							campaign: campaign,
							owner: {
								displayName: data.displayName,
								email: data.email,
								picture: data.picture
							}
						});
					});
				}
			} else {
				message.error(`404 - Campaign ID doesn't exist. Please check the link`);
				navigate(`/`);
			}
		});
		return ref; // pass currentCampaignRef back to url
	};

	const { fsUser, setShowLimitModal, hitEntryLimit, hitEditLimit } = useAuth();

	const [currentCampaignRef, setCurrentCampaignRef] = useState<any>();
	const [campaignData, setCampaignData] = useState<any>();
	const [campaigns, setCampaigns] = useState<any[]>([]);
	const [campaignMembers, setCampaignMembers] = useState<any[]>([]);
	const [folders, setFolders] = useState<any>(null);
	const [pages, setPages] = useState<any>(null);
	const [currentPage, setCurrentPage] = useState('timeline'); // timeline or queryPage
	const [queryPageId, setQueryPageId] = useState(''); // queryPage id
	const [searchPages, setSearchPages] = useState<any[]>([]);
	const [searchFolders, setSearchFolders] = useState<any[]>([]);
	const [searchAll, setSearchAll] = useState<any[]>([]);

	const [startAtDocRef, setTimelineStartAtDocRef] = useState<any>();
	const [timelineRealtimeEntries, setTimelineRealtimeEntries] = useState<any[]>([]);
	const [timelineStaticEntries, setTimelineStaticEntries] = useState<any[]>([]);
	const [pageEntries, setPageEntries] = useState<any[]>([]);
	const [timelineLastDocRef, setTimelineLastDocRef] = useState<any>(null);
	const [timelineLoadMore, setTimelineLoadMore] = useState(true);
	const [entriesRetrieved, setEntriesRetrieved] = useState(false);
	const [showBadgeCount, setShowBadgeCount] = useState(0);
	const [scrollBottom, setScrollBottom] = useState(false);
	const [requestCampaign, setRequestCampaign] = useState({});
	const [autoLinking, setAutoLinking] = useState(true);
	const [sending, setSending] = useState(false);
	const [editEditorOpen, setEditEditorOpen] = useState(false);

	// Get realtime data of a collection and set state
	const listenToCollection = (collection: string, campRef: any, setData: (arg0: any) => void) => {
		const unsubscribe = campRef.collection(collection)
			.orderBy('title', 'asc')
			.onSnapshot((snapshot: any) => {
				// const source = snapshot.metadata.fromCache ? "local cache" : "server";
				// console.log(collection, "Data came from " + source);
				const data = snapshot.docs.map((doc: any) => ({
					...doc.data(),
					key: doc.id
				}));
				setData(data);
			});
		return unsubscribe;
	};

	// Set the current campaign on first render
	useEffect(() => {
		if (fsUser) {
			const prevCampRef = getCampaignRef(fsUser.prevCampaign);
			// Check prevCampaign exists
			if (prevCampRef) {
				prevCampRef.get().then((doc: any) => {
					if (doc.exists) {
						setCurrentCampaignRef(prevCampRef);
					} else {
						// Reset prevCampaign to user id notes if prev is incorrect or now deleted
						firestore.collection('users').doc(fsUser.uid).update({ prevCampaign: fsUser.uid })
							.then(() => {
								const prevCampRef = getCampaignRef(fsUser.prevCampaign);
								prevCampRef && setCurrentCampaignRef(prevCampRef);
							});
					}
				});
			} else {
				// Reset prevCampaign to user id notes if prev is incorrect or now deleted
				firestore.collection('users').doc(fsUser.uid).update({ prevCampaign: fsUser.uid })
					.then(() => {
						const prevCampRef = getCampaignRef(fsUser.prevCampaign);
						prevCampRef && setCurrentCampaignRef(prevCampRef);
					});
			}
		}
		// eslint-disable-next-line
	}, []);

	// Set the current campaign
	useEffect(() => {
		if (currentCampaignRef) {
			const campData = currentCampaignRef.onSnapshot((doc: any) => {
				// const source = doc.metadata.fromCache ? "local cache" : "server";
				// console.log("CampaignRef Data came from " + source);
				const data = {
					...doc.data(),
					id: doc.id
				};
				setCampaignData(data);
			});
			// Get all pages and folders of the campaign
			listenToCollection('folders', currentCampaignRef, setFolders);
			listenToCollection('pages', currentCampaignRef, setPages);
			// Listen for timeline entries with pagination
			timelineEntryListener();
			return campData;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentCampaignRef]);

	// Get all campaigns the user is a member of
	useEffect(() => {
		if (fsUser && fsUser.uid) {
			const userCampaigns = firestore.collection('campaigns')
				.where('members', 'array-contains', fsUser.uid)
				.orderBy('title', 'asc')
				.onSnapshot((snapshot: any) => {
					// const source = snapshot.metadata.fromCache ? "local cache" : "server";
					// console.log("Campaigns Data came from " + source);
					const data = snapshot.docs.map((doc: any) => ({
						...doc.data(),
						id: doc.id
					}));
					setCampaigns(data);
				});
			return userCampaigns;
		}
	}, [fsUser]);

	// Realtime listener for timeline entries
	const timelineEntryListener = async () => {
		let query = currentCampaignRef.collection('entries').orderBy('createdAt', 'desc');

		// Get a starting point doc to listen from
		const lastRealtimeDocRef = await query
			.get().then((snapshot: any) => {
				const dataRef = snapshot.docs;
				const limit = 50;
				return dataRef.length >= limit ? dataRef[limit - 1] : dataRef[dataRef.length - 1];
			});
		setTimelineStartAtDocRef(lastRealtimeDocRef);

		// Only set an endAt limit if there are more entries past the limit
		query = lastRealtimeDocRef ? query.endAt(lastRealtimeDocRef) : query;

		const unsubscribe = query
			.onSnapshot((snapshot: any) => {
				// const source = snapshot.metadata.fromCache ? "local cache" : "server";
				// console.log("Timeline listener data came from " + source);
				const data = snapshot.docs.map((doc: any) => ({
					...doc.data(),
					key: doc.id
				}));
				// only setEntries if timestamp has been set
				// this is to counter batch uploading files and it snapshooting between files
				// but not sure why that means the createdAt is null
				const incomplete = data.find((doc: any) => doc.createdAt === null);
				!incomplete && setTimelineRealtimeEntries(data);
			});
		return unsubscribe;
	};

	// Get entries for a given page. Indexed collection query
	// Have the pageEntries separate so the timeline can state isn't restarted each time a new page is clicked
	// It also means that 'timeline' doesn't have to be added to the 'pages' array of each entry which would be at risk of user deleting it
	useEffect(() => {
		if (currentCampaignRef && queryPageId) {
			// Set page entries
			const unsubscribe = currentCampaignRef.collection('entries')
				.where('pages', 'array-contains', queryPageId)
				.orderBy('createdAt', 'desc')
				.onSnapshot((snapshot: any) => {
					// const source = snapshot.metadata.fromCache ? "local cache" : "server";
					// console.log("Page listener data came from " + source);
					const data = snapshot.docs.map((doc: any) => ({
						...doc.data(),
						key: doc.id
					}));
					// only setEntries if timestamp has been set not sure why createdAt is null, serverTS is async
					const incomplete = data.find((doc: any) => doc.createdAt === null);
					!incomplete && setPageEntries(data);
				});
			return unsubscribe;
		}
	}, [currentCampaignRef, queryPageId]);

	// One time fetch to grab more entries from the timeline, this data won't be updated on client unless refresh
	async function getMoreEntries(cumulativeEntries: any[]) {
		let entryQuery = await currentCampaignRef.collection('entries').orderBy('createdAt', 'desc');

		// If timelineLastDocRef is null that means getMore hasn't been called yet and should start from the last entry of the main listener
		entryQuery = timelineLastDocRef ?
			entryQuery.startAfter(timelineLastDocRef) :
			startAtDocRef ? entryQuery.startAfter(startAtDocRef) : null;

		if (!entryQuery) return;

		const unsubscribe = entryQuery.limit(50).get().then(async (snapshot: any) => {
			// If there are no more docs to get exit from listener
			// Don't exit if this is the first fetch for a page
			if (cumulativeEntries.length && snapshot.empty) {
				return setTimelineLoadMore(false);
			}

			// const source = snapshot.metadata.fromCache ? "local cache" : "server";
			// console.log("More entries came from " + source);
			const data = await snapshot.docs.map((doc: any) => ({
				...doc.data(),
				key: doc.id
			}));

			// only setEntries if timestamp has been set
			// this is to counter batch uploading files and it snapshooting between files
			// but not sure why that means the createdAt is null
			const incompleteData = data.find((doc: any) => doc.createdAt === null);
			const totalTimelineStaticEntries = [...cumulativeEntries, ...data];
			!incompleteData && setTimelineStaticEntries([...totalTimelineStaticEntries]);

			// Use last doc of fetch as starting point of next fetch
			setTimelineLastDocRef(snapshot.docs[data.length - 1]);

			// Send signal to offset scrollbar when loading more entries
			setEntriesRetrieved(true);
		});
		return unsubscribe;
	}

	function uploadEntry(campRef: any, deltaJSON: any, referenceIDs?: string[]) {
		// Check if they've exceeded their free limit unless they're premium
		if (!fsUser.premium && hitEntryLimit) {
			return setShowLimitModal(true);
		}

		return campRef.collection('entries').add({
			createdAt: fieldVal.serverTimestamp(),
			createdBy: fsUser.uid,
			data: deltaJSON,
			pages: referenceIDs || []
		}).then(() => {
			setSending(true);
			if (!fsUser.premium) {
				// Increment their weekly usage
				firestore.collection('users').doc(fsUser.uid).update({
					weeklyEntriesMade: fieldVal.increment(1)
				});
			}
		});
	}
	function editEntry(campRef: any, entryID: any, deltaJSON: any, referenceIDs?: string[]) {
		// Check if they've exceeded their free limit unless they're premium
		if (!fsUser.premium && hitEditLimit) {
			return setShowLimitModal(true);
		}

		return campRef.collection('entries').doc(entryID).update({
			editedAt: fieldVal.serverTimestamp(),
			lastEditedBy: fsUser.uid,
			data: deltaJSON,
			pages: referenceIDs || []
		}).then(() => {
			if (!fsUser.premium) {
				// Increment their weekly usage
				firestore.collection('users').doc(fsUser.uid).update({
					weeklyEditsMade: fieldVal.increment(1)
				});
			}
		});
	}

	// Create union object of campaign members data
	useEffect(() => {
		if (campaignData && campaignData.members && currentCampaignRef) {
			// 'in' method only supports maximum of 10 elements so fetch users individually
			if (campaignData.members.length > 10) {
				let campaignUsers: any[] = [];
				campaignData.members.forEach((memberId: any) => {
					firestore.collection('users')
						.where('uid', '==', memberId)
						.orderBy('displayName', 'asc')
						.onSnapshot((snapshot: any) => {
							const data = snapshot.docs.map((doc: any) => ({
								...doc.data()
							}));
							campaignUsers.push(...data);
						});
				});
				setCampaignMembers(campaignUsers);
			} else {
				const members = firestore.collection('users')
					.where('uid', 'in', campaignData.members)
					.orderBy('displayName', 'asc')
					.onSnapshot((snapshot: any) => {
						// const source = snapshot.metadata.fromCache ? "local cache" : "server";
						// console.log("Campaigns Data came from " + source);
						const data = snapshot.docs.map((doc: any) => ({
							...doc.data()
						}));
						setCampaignMembers(data);
					});
				return members;
			}
		}
	}, [campaignData, currentCampaignRef]);

	// Format files for select components
	useEffect(() => {
		if (pages && folders) {
			const pageOptions = pages
				.filter((obj: any) => (obj.key === 'timeline' ? false : { ...obj }))
				.map((doc: any) => ({ label: doc.title, value: doc.key }));
			setSearchPages(pageOptions);

			let folderOptions = folders.map((doc: any) => ({ label: doc.title, value: doc.key }));
			folderOptions = [{ label: 'Root', value: 'root' }, ...folderOptions];
			setSearchFolders(folderOptions);

			setSearchAll([
				{ label: 'Pages', options: pageOptions },
				{ label: 'Folders', options: folderOptions }
			]);
		}
	}, [pages, folders]);

	const value = {
		campaigns,
		folders,
		pages,
		campaignData,
		campaignMembers,
		getCampaignRef,
		changeCampaign,
		currentPage,
		setCurrentPage,
		queryPageId,
		setQueryPageId,
		searchPages,
		searchFolders,
		searchAll,
		uploadEntry,
		editEntry,
		timelineLoadMore,
		entriesRetrieved,
		setEntriesRetrieved,
		getMoreEntries,
		timelineRealtimeEntries,
		timelineStaticEntries,
		pageEntries,
		currentCampaignRef,
		showBadgeCount,
		setShowBadgeCount,
		requestCampaign,
		scrollBottom,
		setScrollBottom,
		autoLinking,
		setAutoLinking,
		sending,
		setSending,
		editEditorOpen,
		setEditEditorOpen,
	};
	return (
		<FirestoreContext.Provider value={value} >
			{children}
		</FirestoreContext.Provider>
	);
}

export const createCampaign = (title: string, uid: string, onUserCreation = false) => {
	const campaigns = firestore.collection('campaigns');
	const fields = {
		createdAt: fieldVal.serverTimestamp(),
		createdBy: uid,
		title: title,
		members: [uid]
	};
	return onUserCreation ? campaigns.doc(uid).set(fields) : campaigns.add(fields);
};
export const editCampaign = (campaignId: string, title: string, uid: string) => {
	const campaigns = firestore.collection('campaigns');
	const fields = {
		editedAt: fieldVal.serverTimestamp(),
		editedBy: uid,
		title: title,
	};
	return campaigns.doc(campaignId).update(fields);
};
