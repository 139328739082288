import React from 'react';
import { Router } from '@reach/router';
import './App.less';
import { AuthProvider } from './contexts/AuthContext';
import * as Sentry from '@sentry/react';
import { LoadingOutlined } from '@ant-design/icons';
import { Row, Spin } from 'antd';
import Loadable from '@loadable/component';

// Loading screen
const antIcon = <LoadingOutlined style={{ fontSize: 64 }} spin />;
const RenderLoader = <div style={{ minHeight: '100vh', background: 'linear-gradient(175deg, rgba(174,203,217,1) 0%, rgba(181,209,221,1) 25%, rgba(188,215,224,1) 50%, rgba(214,225,229,1) 75%)' }}><Row style={{ minHeight: '100vh' }} justify='center' align='middle'><Spin indicator={antIcon} /></Row></div>;

// Use Loadable to split code, only load code when it's called to increase performance
const LandingPage = Loadable(() => import('./pages/LandingPage'),
	{ fallback: RenderLoader }
);
const Terms = Loadable(() => import('./pages/Terms'),
	{ fallback: RenderLoader }
);
const CampaignPage = Loadable(() => import('./pages/CampaignPage'),
	{ fallback: RenderLoader }
);

function App() {
	window.addEventListener('error', function (error) {
		Sentry.captureException(error);
		process.env.NODE_ENV === 'production' && window.location.reload();
	});

	return (
		<AuthProvider>
			<Router>
				<CampaignPage path='/:campaignId/' default />
				<Terms path='/terms' />
				<LandingPage path='/' />
			</Router>
		</AuthProvider>
	);
}

export default App;
