import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/firestore';
import 'firebase/storage';
import * as Sentry from '@sentry/react';

const app = !firebase.apps.length ?
	firebase.initializeApp({
		apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
		authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
		databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
		projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
		storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
		messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
		appId: process.env.REACT_APP_FIREBASE_APP_ID
	})
	:
	firebase.app(); // if already initialized, use that one. Solves duplicate tabs error

// Cache data if there aren't duplicate tabs
!firebase.apps.length && firebase.firestore().enablePersistence().catch(error => {
	Sentry.captureException(error);
});

// Firestore security rules allow read/write access on all documents to any user signed in to the application

export const provider = new firebase.auth.GoogleAuthProvider();
export const auth = app.auth();
export const functions = app.functions();
export const firestore = app.firestore();
export const storage = app.storage();
export const fieldVal = firebase.firestore.FieldValue;
export default app;
